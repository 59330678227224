.scss11{
  bottom: -50px;
  z-index: 3;
  left: 80%;
}

@media (max-width:800px){
  .scss11{
    display: none;
    opacity: 0;
    visibility: hidden;
  }
}
