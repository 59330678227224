@tailwind base;
@tailwind components;
@tailwind utilities;



body {
  margin: 0;
  padding: 0;
  font: 300 11px/1.4 'Helvetica Neue', sans-serif;
  color: #444;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #0f1300;
  overflow: hidden;
  display: block;
}

