.home-page {
  .exp{
    display: block;
    position: absolute;
    opacity: 0;
    float: left;
    color: rgb(255, 255, 255);
    font-size: 10px;
    margin-top: 3px;
  }
  .text-zone {
    position: absolute;
    left: 10%;
    top: 50%;
    transform: translateY(-50%);
    width: fit-content;
    max-height: 90%;
    display: inline-block;
    z-index: 5;
  }
  
  h1 {
    color: #fff;
    font-size: 56px;
    line-height: 53px;
    margin: 0;
    font-family: 'Coolvetica';
    font-weight: 400;

    &:before {
      content: '<h1>';
      font-family: 'La Belle Aurore', cursive;
      color: #ffd700;
      font-size: 18px;
      position: absolute;
      margin-top: -40px;
      left: -15px;
      
      animation: fadeIn 1s 1.7s backwards;
    }

    &:after {
      content: '<h1/>';
      font-family: 'La Belle Aurore', cursive;
      color: #ffd700;
      font-size: 18px;
      position: absolute;
      margin-top: -7px;
      margin-left: -15px;
      
      
      animation: fadeIn 1s 1.7s backwards;
    }

    img {
      margin-left: 20px;
      margin-top: -23px;
      opacity: 0;
      width:65px;
      height: auto;
      animation: rotateIn 1s linear both;/*rotate,1s long,*/
      animation-delay: 1.4s;
      display: inline;
    }
  }

  h2 {
    color: #bdbdbd;
    margin-top: 30px;
    font-weight: 400;
    font-size: 16px;
    font-family: sans-serif;
    letter-spacing: 3px;
    animation: fadeIn 1s 1.8s backwards;
  }

  .flat-button {
    color: #ffd700;
    font-size: 13px;
    font-weight: 400;
    letter-spacing: 4px;
    font-family: sans-serif;
    text-decoration: none;
    padding: 10px 18px;
    border: 1px solid #ffd700;
    margin-top: 25px;
    float: left;
    animation: fadeInAnimation 1s 1.8s backwards;
    white-space: nowrap;

    &:hover {
      background: #ffd700;
      color: #333;
      
    }
  }
}
@media (max-width:800px){
  .container{
    left: -12%;
    
    h1{
      font-size: 40px;
      img{
        width: 45px;
        margin-top: -13px;
      }
    }
    
  }
  .home-page{
    .exp{
    opacity: 0.3;
  }}
}
@media (max-width:350px){
  .container{
    left: -7%;
    margin-top: -70px;
    left: -12%;
  }
}

