.nav-bar {
    background: #181818;
    width: 60px;
    height: 100%;
    position: absolute;
    top: 0;
    z-index: 3;
   
    right: 0;
  
    .logo {
      display: block;
      padding: 8px 0;
  
      img {
        display: block;
        margin: 8px auto;
        width: 58px;
        height: auto;
  
        &.sub-logo {
          width: 55px;
        }
      }
    }
  
    nav {
      display: block;
      text-align: center;
      position: absolute;
      height: 210px;
      top: 50%;
      margin-top: -120px;
      width: 100%;
  
      a {
        font-size: 28px;
        color: #4d4d4e;
        display: block;
        line-height: 51px;
        height: 100px;
        position: relative;
        text-decoration: none;
  
        i {
          transition: all 0.3s ease-out;
        }
  
        &:hover {
          color: #81d7ff;
  
          svg {
            opacity: 0;
          }
  
          &:after {
            opacity: 1;
          }
        }
  
        &:after {
          content: '';
          font-size: 9px;
          letter-spacing: 2px;
          position: absolute;
          bottom: 50px;
          display: block;
          width: 100%;
          text-align: center;
          opacity: 0;
          -webkit-transition: all 0.3s ease-out;
          transition: all 0.3s ease-out;
        }
        &:first-child{
          &::after{
            content: "HOME";
          }
        }}
        a.contact-link{
          &:after{
            content: 'CONTACT';
          }
        }
        a.about-link{
          &:after{
            content: 'ABOUT';
          }
        }
        a.active{
          svg{
            color: #81d7ff;
          }
        }
  
      }
      ul {
        position: absolute;
        bottom: 20px;
        width: 100%;
        display: block;
        padding: 0;
        list-style: none;
        text-align: center;
        margin: 0;
    
        li {
          a {
            padding: 7px 0;
            display: block;
            font-size: 15px;
            line-height: 16px;
            color: #4d4d4e;
          }
    
          &:hover {
            .anchor-icon {
              color: #81d7ff;
            }
          }
        }
      }
    }
@media (max-width: 800px){
  .nav-bar{
    width: 40px;
    nav{
      a{
        font-size: 25px;
        height: 70px;
        line-height: 50px;
        &::after{
          font-size: 5.4px;
          bottom: 20px;
        }
        
      }
    }
  }
}
